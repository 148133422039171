import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
        'Productosyservicios': 'Various Products and Services',
        'InitialDes': 'At Proserva, we are committed to the certainty and genuine guarantee to satisfy the market, establishing a solid presence in the North, South and Central American region since our founding in 1997. With more than 12 years of previous experience in various industries in the region and Mexico, our founder, an experienced engineer, gave life to Proserva with the vision of creating a multi-sector company to meet industrial needs locally and internationally. This now adds up to about 40 years of experience in addition to the expertise of a multidisciplinary team of engineers, business administrators, finance professionals, agronomists, etc.',
        'Diseño de Plantas Industriales': 'Industrial Plant Design',
        'Diseño de Pl desciption': 'We design industrial plants to optimize processes and maximize production efficiency. Our focus is on developing tailor-made solutions that meet the needs of our clients in the industrial field.',
        'Diseño y Fabricación de equipos Industriales': 'Design and manufacture of industrial equipment',
        'Diseño y Fabricación desciption': 'We manufacture and design industrial equipment, providing innovative and high quality solutions to improve our customers production processes.',
        'Formulación Industrial': 'Industrial Formulation',
        'Formulación Industrial desc': 'We develop formulas for the manufacture of industrial products, guaranteeing quality and compliance with standards. We also offer manufacturing and assembly of products.',
        'Asesoría en diseño de Procesos': 'Process design consultancy',
        'Asesoría en diseño desc': 'We offer process design consulting services, providing tailored solutions to optimize efficiency and productivity in the operation of your business. Our focus is on identifying areas of improvement and developing customized strategies to achieve your companys specific objectives.',
        'Energía Solar y Eólica': 'Solar and Wind Energy',
        'Energía Solar y desci': 'We design and implement power generation systems that meet our customers energy needs while promoting environmental sustainability.',
        'Trader': 'Trader',
        'Trader desc': 'We export and import products from Central and South America to North America, Europe, some Arab countries and Israel.',
        'Industrias y Experiencias': 'Industries and Experiences',
        'Industrias y Experiencias desci': 'Our footprint extends beyond the Northern Triangle of Central America, reaching international clients in Europe, North, South and Central America. We have cultivated an impeccable reputation with a zero claims record to date. We excel in serving mainly the pharmaceutical, food and cosmetic industries (80%). In addition, we have provided our services to other industries, such as paint, soap, detergents, agrochemicals, animal feed and fodder, and textiles (20%).',
        'Contacto': 'Contact',
        'servicios': 'Services',
        'industrias': 'Industries',
        'contacto': 'contact',
        'ofertas':'Our offers of the month',
        'ofertas desciption': 'see offers',
        'Asesoria administrativa, financiera': 'Administrative, Financial and Marketing Consultancy',
        'Asesoria administrativa desc': 'We offer consulting in administration, finance and marketing for companies. Our expert team optimizes processes and strategies to boost business success.'
    }
  },
  de: {
    translation: {
        'Productosyservicios': 'Diverse Produkte und Dienstleistungen',
        'InitialDes': 'Wir von Proserva haben uns der Sicherheit und der echten Garantie verschrieben, den Markt zufrieden zu stellen, und haben seit unserer Gründung im Jahr 1997 eine solide Präsenz in Nord-, Süd- und Mittelamerika aufgebaut. Unser Gründer, ein erfahrener Ingenieur, verfügt über mehr als 12 Jahre Erfahrung in verschiedenen Branchen in der Region und in Mexiko und rief Proserva mit der Vision ins Leben, ein branchenübergreifendes Unternehmen zu schaffen, das die Bedürfnisse der Industrie auf lokaler und internationaler Ebene erfüllt. Heute verfügt Proserva über rund 40 Jahre Erfahrung und das Fachwissen eines multidisziplinären Teams aus Ingenieuren, Geschäftsführern, Finanzfachleuten, Agronomen usw.',
        'Diseño de Plantas Industriales': 'Industrieanlagenplanung',
        'Diseño de Pl desciption': 'Wir planen Industrieanlagen, um Prozesse zu optimieren und die Produktionseffizienz zu maximieren. Unser Schwerpunkt liegt auf der Entwicklung maßgeschneiderter Lösungen, die den Bedürfnissen unserer Kunden im industriellen Bereich entsprechen.',
        'Diseño y Fabricación de equipos Industriales': 'Planung und Herstellung von Industrieanlagen',
        'Diseño y Fabricación desciption': 'Wir produzieren und entwickeln Industrieanlagen und bieten maßgeschneiderte Lösungen zur Verbesserung der Produktionsprozesse unserer Kunden.',
        'Formulación Industrial': 'Industrielle Formulierung',
        'Formulación Industrial desc': 'Wir entwickeln spezielle Formeln für die Herstellung von Industrieprodukten und garantieren dabei Qualität und die Einhaltung von Normen. Wir bieten auch die Herstellung und Montage von Produkten an.',
        'Asesoría en diseño de Procesos': 'Beratung bei der Prozessgestaltung',
        'Asesoría en diseño desc': 'Wir beraten Sie bei der Prozessgestaltung und bieten maßgeschneiderte Lösungen zur Optimierung von Effizienz und Produktivität in Ihrem Unternehmen. Unser Schwerpunkt liegt auf der Ermittlung von Verbesserungspotenzialen und der Entwicklung maßgeschneiderter Strategien zur Erreichung der spezifischen Ziele Ihres Unternehmens.',
        'Energía Solar y Eólica': 'Solar- und Windenergie',
        'Energía Solar y desci': 'Wir entwickeln und implementieren Stromerzeugungssysteme, die den Energiebedarf unserer Kunden decken und gleichzeitig die ökologische Nachhaltigkeit fördern.',
        'Trader': 'Trader',
        'Trader desc': 'Wir exportieren und importieren Produkte aus Mittel- und Südamerika nach Nordamerika, Europa, einigen arabischen Ländern und Israel.',
        'Industrias y Experiencias': 'Industrien und Erfahrung',
        'Industrias y Experiencias desci': 'Unsere Präsenz geht über das nördliche Dreieck von Mittelamerika hinaus und erreicht internationale Kunden in Europa, Nord-, Süd- und Mittelamerika. Wir haben uns einen tadellosen Ruf erworben, und bis heute gab es keine einzige Reklamation. Wir sind vor allem in der Pharma-, Lebensmittel- und Kosmetikindustrie tätig (80 %). Darüber hinaus haben wir unsere Dienste auch anderen Branchen wie der Farben-, Seifen-, Waschmittel-, Agrochemie-, Futtermittel- und Textilindustrie (20 %) angeboten.',
        'Contacto': 'Kontakt',
        'servicios': 'Dienstleistungen',
        'industrias': 'Industrien',
        'contacto': 'Kontakt',
        'ofertas':'Unsere Angebote des Monats',
        'ofertas desciption': 'siehe Angebote',
        'Asesoria administrativa, financiera': 'Beratung in den Bereichen Verwaltung, Finanzen und Marketing',
        'Asesoria administrativa desc': ' Wir bieten Management-, Finanz- und Marketingberatung für Unternehmen. Unser Expertenteam optimiert Prozesse und Strategien, um den Geschäftserfolg zu steigern.'
    }
  },
  es:{
    translation:{
        'Productosyservicios': 'Productos y Servicios Varios',
        'InitialDes': 'En Proserva, nos comprometemos con la certeza y garantía genuina para satisfacer el mercado, estableciendo una sólida presencia en la región Norte, Sur y Centro América desde nuestra fundación en 1997. Con más de 12 años de experiencia previa en diversas industrias en la región y México, nuestro fundador, un experimentado ingeniero, dió vida a Proserva con la visión de crear una empresa multisectorial para satisfacer las necesidades industriales a nivel local e internacional. \nLo que ahora suma alrededor de 40 años de experiencia además de la experiencia de un equipo multidisciplinario de ingenieros, administradores de empresas, profesionales en finanzas, agrónomos, etc.',
        'Diseño de Plantas Industriales': 'Diseño de Plantas Industriales',
        'Diseño de Pl desciption': 'Diseñamos plantas industriales para optimizar procesos y maximizar la eficiencia de la producción. Nuestro enfoque se centra en desarrollar soluciones a la medida que satisfagan las necesidades de nuestros clientes en el ámbito industrial.',
        'Diseño y Fabricación de equipos Industriales': 'Diseño y Fabricación de equipos Industriales',
        'Diseño y Fabricación desciption': 'Fabricamos y diseñamos equipos industriales, brindando soluciones innovadoras y de alta calidad para mejorar los procesos de producción de nuestros clientes.',
        'Formulación Industrial': 'Formulación Industrial',
        'Formulación Industrial desc': 'Desarrollamos fórmulas para la fabricación de productos industriales, garantizando calidad y cumplimiento de estándares. También ofrecemos fabricación y maquila de productos.',
        'Asesoría en diseño de Procesos': 'Asesoría en diseño de Procesos',
        'Asesoría en diseño desc': 'Ofrecemos servicios de asesoría en diseño de procesos, brindando soluciones a la medida para optimizar la eficiencia y la productividad en la operación de su negocio. Nuestro enfoque se centra en identificar áreas de mejora y desarrollar estrategias a la medida para alcanzar los objetivos específicos de su empresa.',
        'Energía Solar y Eólica': 'Energía Solar y Eólica',
        'Energía Solar y desci': 'Diseñamos e implementamos sistemas de generación eléctrica que satisfagan las necesidades energéticas de nuestros clientes, mientras promovemos la sostenibilidad ambiental.',
        'Trader': 'Trader',
        'Trader desc': 'Exportamos e Importamos productos de Centro y Sur América hacia Norte América, Europa, algunos países Arabes e Israel.',
        'Industrias y Experiencias': 'Industrias y Experiencias',
        'Industrias y Experiencias desci': 'Nuestra huella se extiende más allá del Triángulo Norte de Centroamérica, llegando a clientes internacionales en Europa, Norte, Sur y Centro de América. Hemos cultivado una reputación impecable con un récord de cero reclamos hasta la fecha. Nos destacamos en atender principalmente a la industria farmacéutica, alimenticia y cosmética (80%). Además, hemos brindado nuestros servicios a otras industrias, como la fabricación de pinturas, jabones, detergentes, agroquímicos, alimentos y forrajes para animales y el sector textil. (20%)',
        'Contacto': 'Contacto',
        'servicios': 'Servicios',
        'industrias': 'Industrias',
        'contacto': 'Contacto',
        'ofertas':'Nuestras ofertas del Mes',
        'ofertas desciption': 'ver ofertas',
        'Asesoria administrativa, financiera': 'Asesoría Administrativa, Financiera, Mercadológica',
        'Asesoria administrativa desc': 'Ofrecemos asesoría en administración, finanzas y marketing para empresas. Nuestro equipo experto optimiza procesos y estrategias para impulsar el éxito empresarial.',
        'PalabrasTitulo': 'Un poco sobre nuestro ámbito:',
        'Palabras1': 'En el ámbito del diseño industrial y la ingeniería, se abarca una amplia gama de sectores productivos, desde la elaboración de alimentos hasta la manufactura de productos farmacéuticos. Se destaca el diseño de fábricas de alimentos y la fabricación de equipos especializados para sectores como los ingenios azucareros o las embotelladoras. Los laboratorios farmacéuticos también son objeto de diseño, al igual que las fábricas de cosméticos y productos de limpieza. La ingeniería se aplica en fábricas textiles, equipos agrícolas, fábricas de pintura, pulverizadoras de minerales y plantas de extracción de productos naturales, buscando eficiencia y calidad en la producción.',
        'Palabras2': 'En la industria, la eficiencia es clave. Desde mezcladores de líquidos hasta llenadoras de sólidos, cada equipo desempeña un papel crucial. Los sistemas de elevación y pesaje garantizan precisión, mientras que marmitas y reactores son el corazón de la cocina industrial. Tanques para solventes y combustibles aseguran un flujo constante. Equipos de acero inoxidable, como molinos y sistemas de cocción, ofrecen durabilidad e higiene. Estos componentes son pilares del éxito en la producción.',
        'Palabras3': 'En la fabricación, la diversidad es esencial. Productos farmacéuticos, alimentos y cosméticos, cada sector tiene sus propias demandas. Los productos agrícolas requieren cuidado especial, al igual que los plásticos y hules en su procesamiento. Las pinturas, junto con los químicos textiles y para la construcción, agregan complejidad al panorama. Cada uno de estos sectores demanda equipos especializados para garantizar calidad y eficiencia en la producción.',
        'Palabras4': 'En el panorama energético, la solar y la eólica son vitales. La solar capta la radiación solar para producir electricidad, mientras que la eólica aprovecha el viento. No obstante, las hidroeléctricas son esenciales también. Convierten la energía cinética del agua en electricidad, ofreciendo una fuente fiable y renovable. Esta diversificación promueve la sustentabilidad y la seguridad energética. En este contexto de producción diversificada y eficiente, la ingeniería y el diseño industrial se convierten en pilares fundamentales para el desarrollo y la sostenibilidad de diversos sectores industriales, desde la alimentación hasta la energía.'
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "es", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;