import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Ofertas(){
    const [currentDay, setCurrentDay] = useState('');
    const [currentMonth, setCurrentMonth] = useState('');
    const [loader, setLoader] = useState(true)

    useEffect(() => {
      const currentDate = new Date();
      const day = currentDate.toLocaleString('default', { weekday: 'long' });
      const month = currentDate.toLocaleString('default', { month: 'long' });
      setCurrentDay(day);
      setCurrentMonth(month);
        setTimeout(()=>{
            setLoader(false)
        },[1000])
    }, []);


    return <>
    <header className=" fixed w-full ">
        <h2 className="z-50 lg:ml-20 ml-10 mt-10 cormorant font-bold lg:text-6xl text-5xl ">PROSERVA</h2>
    </header>
    <img alt="background lines" className="z-20 w-full opacity-30 h-screen absolute" src="./cardboard-texture.jpg"></img>
    
    <main className="z-50 ofertasMain h-screen w-full">
        
        <div className="z-50 relative">
            <p className="lg:pt-40 pt-20 italic ml-10 lg:text-xl lg:ml-20 mb-5">There are no offers on {currentMonth} 2024</p>
            <Link className="border z-50 border-black pr-4 pl-4 pt-3 pb-3 rounded-full ml-10 lg:ml-20" to={'/'}>return</Link>
        </div>



    </main>
    <div className="h-screen w-full z-50 fixed bg-red-100"></div>


    </>
}