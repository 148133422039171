import './App.css';
import { useEffect, useState } from "react";
import { useRef } from "react";
import ContactForm from './contact-form';

import disefabrocacion from './filler.jpg'
import interiorImage from './interior.jpg'
import formulacionImage from './formulacion.jpg'
import processImage from './process.jpg'
import solarImage from './solarF.jpg'
import contenedoresImage from './contenedores.jpg'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import asesoriaAdminImage from './asesoriaAdministrativa.jpg'

function App() {
  const { t, i18n } = useTranslation();
  const [popup, setPopup] = useState(false)
  const[current, setCurrent] = useState(0)

  const contactRef = useRef()
  const industriesRef = useRef()
  const servicesRef = useRef()

  useEffect(()=>{
    setTimeout(()=>{
      setPopup(true)
    }, [3000])
  },[])

  const [isOpen, setIsOpen] = useState(false);
  const languagesArray = ['es', 'en', 'de']
  const [activeLn, setActiveLn] = useState(0)


  useEffect(()=>{
    i18n.changeLanguage(languagesArray[activeLn])
  },[activeLn])

return <>
    
    <header className=' header justify-center flex w-full  fixed'>
      <div className='headerwidth flex text-md md:text-lg  items-center pt-1 pb-1 pl-2 pr-2 justify-between'>

        <img src='./logo.svg' className='hover:cursor-pointer h-10'></img>
        <div className='flex space-x-4 items-center'>
        <div className='underline hover:cursor-pointer relative'>
          <p onClick={()=>{
            setIsOpen(prev=>!prev)
          }}>{languagesArray[activeLn]}</p>
          <div>
            {isOpen && <ul className='absolute'>
              <li onClick={()=>{
                setActiveLn(2)
                setIsOpen(prev=>!prev)
              }}>de</li>
              <li onClick={()=>{
                setActiveLn(1)
                setIsOpen(prev=>!prev)
              }}>en</li>
              <li onClick={()=>{
                setActiveLn(0)
                setIsOpen(prev=>!prev)
              }}>es</li>
            </ul>}
          </div>
        </div>
          <nav className='hover:cursor-pointer hover:underline' onClick={()=>{
            servicesRef.current.scrollIntoView({behavior: 'smooth'})
          }} >{t('servicios')}</nav>
          <nav className='hidden md:inline hover:cursor-pointer hover:underline' onClick={()=>{
            industriesRef.current.scrollIntoView({behavior: 'smooth'})
          }} >{t('industrias')}</nav>
          <nav className='hover:bg-black hover:cursor-pointer hover:text-white contactbutton rounded-full pl-3 pr-3 pt-2 pb-2' onClick={()=>{
            contactRef.current.scrollIntoView({behavior: 'smooth'})
          }} >{t('contacto')}</nav>
        </div>
      </div>
    </header>
    {popup &&     <div className='h-40 w-60 lg:h-80 lg:w-80 lg:text-2xl flex flex-col items-center justify-center popup'>
      <div onClick={()=>{
        setPopup(false)
      }} className='h-8 w-8 flex items-center justify-center absolute hover:cursor-pointer right-0 top-0 text-2xl'>x</div>
      <p>{t('ofertas')}</p>
      <Link to={'/ofertas'} className='rounded-full border lg:font-bold lg:text-lg mt-3 pl-4 pr-4 pt-2 pb-2 border-black'>{t('ofertas desciption')}</Link>
    </div>}


    <main className=' w-full'>


      {/* MainSection */}
      <section className=' mainsection relative h-screen flex justify-center items-center w-full '>

        <div className='z-50'>
          <h1 className='lg:text-9xl cormorant font-bold text-6xl'>PROSERVA</h1>
          <h2 className='proSerVa italic opacity-50 text-xl lg:text-3xl'>{t('Productosyservicios')}</h2>
        </div>

        <img src='./backgroundlines.png' alt='background lines' className='z-10 opacity-10 w-full h-screen absolute' style={{objectFit: 'cover'}}></img>
        <img src='./cardboard-texture.jpg' alt='background texture' className='z-20 opacity-20 fixed  w-full h-screen '></img>


      </section>

      {/* SecondarySection */}
      <section className=' relative w-full flex justify-center'>
      <img src='./backgroundlines.png' alt='background lines' className='z-10 opacity-10 w-full h-screen absolute' style={{objectFit: 'cover'}}></img>
        <div className='z-50 secondarySection flex flex-col items-center'>
          <p className='mt-40 text-lg italic lg:text-2xl text-blue-900 w-full' style={{marginLeft: '-10px'}}>1997-</p>
          <p ref={servicesRef} className='text-justify pb-20 lg:text-3xl'>{t('InitialDes')}</p>
        </div>
      </section>


      {/* ThirdSecction */}
      <section className=' thirdSection w-full mt-20 lg:mt-60 lg:text-xl flex justify-center'>

      <div  className={`z-50 serviceContainer `}>

          <div className='service '  onClick={()=>{
            setCurrent(1)
          }} style={{borderTop: '1px solid black'}}>
            <h2 className='hover:underline hover:cursor-pointer'>{t('Diseño de Plantas Industriales')}</h2>
            <div className={`${current ===1 ? 'block' : 'hidden'}`}>
              <p >{t('Diseño de Pl desciption')}</p>
              <img alt='diseño de planta industrial' src={interiorImage}  style={{borderRadius: '10px'}}></img>
            </div>
            

          </div>
          <div className='service ' onClick={()=>{
            setCurrent(2)
          }} >
            <h2 className='hover:underline hover:cursor-pointer'>{t('Diseño y Fabricación de equipos Industriales')}</h2>
            <div className={`${current ===2 ? 'block' : 'hidden'}`}>
              <p>{t('Diseño y Fabricación desciption')}</p>
              <img alt='diseño de equipos industriales' src={disefabrocacion} className='mb-4' style={{borderRadius: '10px'}}></img>
            </div>
          </div>
          {/* cccccccccccccccccccccccccccccccccccc */}
          <div className='service ' onClick={()=>{
            setCurrent(3)
          }} >
            <h2 className='hover:underline hover:cursor-pointer'>{t('Asesoria administrativa, financiera')}</h2>
            <div className={`${current ===3 ? 'block' : 'hidden'}`}>
              <p>{t('Asesoria administrativa desc')}</p>
              <img alt='formulación industrial' src={asesoriaAdminImage} className='mb-4' style={{borderRadius: '10px'}}></img>
            </div>
            
          </div>
          {/*  */}
          <div className='service ' onClick={()=>{
            setCurrent(4)
          }} >
            <h2 className='hover:underline hover:cursor-pointer'>{t('Formulación Industrial')}</h2>
            <div className={`${current ===4 ? 'block' : 'hidden'}`}>
              <p>{t('Formulación Industrial desc')}</p>
              <img alt='formulación industrial' src={formulacionImage} className='mb-4' style={{borderRadius: '10px'}}></img>
            </div>
            
          </div>
          <div className='service ' onClick={()=>{
            setCurrent(5)
          }} >
            <h2 className='hover:underline hover:cursor-pointer'>{t('Asesoría en diseño de Procesos')}</h2>
            <div className={`${current ===5 ? 'block' : 'hidden'}`}>
              <p>{t('Asesoría en diseño desc')}</p>
              <img src={processImage} alt='asesoría en diseño de procesos industriales' className='mb-4' style={{borderRadius: '10px'}}></img>
            </div>
            
          </div>
          <div className='service ' onClick={()=>{
            setCurrent(6)
          }} >
            <h2 className='hover:underline hover:cursor-pointer'>{t('Energía Solar y Eólica')}</h2>
            <div className={`${current ===6 ? 'block' : 'hidden'}`}>
              <p>{t('Energía Solar y desci')}</p>
              <img alt='soluciones de energía solar' src={solarImage} className='mb-4' style={{borderRadius: '10px'}}></img>
            </div>
            
          </div>
          <div className='service '  onClick={()=>{
            setCurrent(7)
          }} >
            <h2 className='hover:underline hover:cursor-pointer'>{t('Trader')}</h2>
            <div className={`${current ===7 ? 'block' : 'hidden'}`}>
              <p>{t('Trader desc')}</p>
              <p className='pt-6 text-lg lg:text-2xl font-bold'>Mercaderías diversas</p>
              <p className='pt-6 text-lg lg:text-xl'>Como:</p>
              <ul className='pl-5 pb-5'>
                <li className='pl-4 before:content'>Mobiliarios</li>
                <li className='pl-4 before:content'>Equipos Industriales</li>
                <li className='pl-4 before:content'>Equipos Electrónicos y Equipos de Cómputo</li>
                <li className='pl-4 before:content'>Equipos de Refrigeración Industrial</li>
                <li className='pl-4 before:content'>Repuestos</li>
                <li className='pl-4 before:content'>Vehículos</li>
                <li className='pl-4 before:content'>Electrodomésticos</li>
                <li className='pl-4 before:content'>Equipos de Refrigeración y Aire Acondicionado</li>
              </ul>
              <img alt='exportación e importación de productos' src={contenedoresImage} className='mb-4' style={{borderRadius: '10px'}}></img>
            </div>
            
          </div>
        
        </div>

      </section>

      {/* FourthSection */}
      <section className='w-full flex justify-center'>
        <div className='z-50 serviceContainer lg:mb-60'>
          <h2 ref={industriesRef} className='cormorant font-bold mb-8 text-3xl mt-40 lg:mt-80 lg:text-5xl'>{t('Industrias y Experiencias')}</h2>
          <p className='mb-40 lg:text-2xl'>{t('Industrias y Experiencias desci')}</p>
          
          
          <h2 className='cormorant font-bold mb-8 text-3xl mt-40 lg:mt-80 lg:text-5xl'>{t('PalabrasTitulo')}</h2>
          <p className='mb-10 lg:text-2xl'>{t('Palabras1')}</p>
          <p className='mb-10 lg:text-2xl'>{t('Palabras2')}</p>
          <p className='mb-10 lg:text-2xl'>{t('Palabras3')}</p>
          <p className='mb-40 lg:text-2xl'>{t('Palabras4')}</p>
        </div>

      </section>
    
    </main>

    <footer className=' w-full flex flex-col items-center justify-center'>

      <div className='p-4 mb-4 lg:justify-center lg:pt-20 lg:pb-20 lg:space-x-20 contact items-center z-50 contactSection flex  '>
          <div className='contactContainer md:pl-8'>
              <p ref={contactRef} className='cormorant font-bold text-2xl  mb-2 lg:text-6xl lg:mb-8 lg:mt-8'>{t('contacto')}</p>
          <ContactForm />
          </div>

          <div>
            <img src='./logo.svg' className='hidden lg:inline logofooter h-80'></img>
          </div>
          </div>
          


    </footer>
  </>
}

export default App;
